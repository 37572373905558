/* eslint-disable no-plusplus */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DEALERID } from '../shared/Constants';
import { DealerContext } from '../shared/contexts/DealerContext';
import { VehicleContext } from '../shared/contexts/VehicleContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '100px 15px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 500px)': {
      padding: '0px 0px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      padding: '0px 0px',
    },
  },
  innerHolderFilter: {
    maxWidth: '1770px',
    width: '100%',
    margin: ' 0 auto',
  },
  innerHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: ' 0 auto',
    padding: '0px 50px',
    width: '80%',
    '@media (max-width: 500px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
  },
  card: {
    minWidth: 'calc(100% / 3 - 40px )',
    maxWidth: 'calc(100% / 3 - 40px )',
    cursor: 'pointer',
    borderRadius: '10px',
    margin: '20px ',
    padding: '40px',
    background: 'white',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px solid #eef6f6',
    '&:hover': {
      background: 'white',
      border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    },
    '@media (max-width: 425px)': {
      minWidth: '100%',
      margin: '20px 0px',
    },
    '@media (min-width: 426px) and (max-width: 842px)': {
      minWidth: 'calc(100% / 2 - 40px )',
      margin: '  10px ',
    },
  },
  cardTextHolder: {
    flex: 1,
    padding: '0px',
    color: 'white',
    '@media (max-width: 768px)': {},
  },
  cardTextHolderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px',
  },
  cardTextTitleHolder: {
    marginBottom: '10px',
    textAlign: 'center',
  },
  cardTextTitle: {
    fontSize: '19px',
    fontWeight: '500',
  },
  imageHolder: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: '0px',
  },
  cardTextTitleAccent: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  cardTextTitleAccentTop: {
    fontSize: '25px',
    color: 'black',
    margin: '10px 0px',
  },
  cardTextTitleAccentMore: {
    fontSize: '16px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    textTransform: 'uppercase',
    margin: '5px 0px',
  },
  groupCard: {
    width: 'calc(100% / 3 - 40px )',
    minWidth: 'calc(100% / 3 - 40px )',
    cursor: 'pointer',
    borderRadius: '10px',
    margin: '20px ',
    padding: '10px ',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    border: '2px solid #eef6f6',
    '&:hover': {
      background: 'white',
      border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    },
    '@media (max-width: 425px)': {
      width: 'calc(100% / 1 )',
      minWidth: 'calc(100% / 1  )',
      margin: '20px 0px',
    },
    '@media (min-width: 426px) and (max-width: 842px)': {
      width: 'calc(100% / 2 - 20px )',
      margin: '  10px ',
    },
  },
  groupCardLogo: {
    display: 'flex',
    flex: 1,
    margin: '10px 0px',
    aspectRatio: '16/9',
    objectFit: 'contain',
  },
  groupCardImg: {
    display: 'flex',
    flex: 4,
    objectFit: 'contain',
  },
  btnHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: ' 0 auto',
    padding: '0px 50px',
    width: '80%',
    '@media (max-width: 500px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
  },
  btn: {
    padding: '10px 35px',
    border: 'none',
    cursor: 'pointer',
    background: '#083246',
    margin: '10px',
    borderRadius: '50px',
    color: 'white',
  },
}));

const ShowRoom = () => {
  const history = useHistory();
  const { newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const [vehicles, setVehicles] = useState(newVehiclesList);
  const [vehicleCleaned, setVehicleCleaned] = useState([]);

  const [activeMake, setActiveMake] = useState('');

  useEffect(() => {
    if (newVehiclesList) {
      setActiveMake('');
      setVehicles(newVehiclesList);
      if (newVehiclesList.length > 0) {
        const branded = newVehiclesList
          .filter((value, index, self) => index === self.findIndex((t) => t.make === value.make))
          .map((v) => globalDealer?.relatedBrands?.filter((m) => m.id === v.makeId)[0]);
        setVehicleCleaned(branded);
      } else {
        setVehicleCleaned([]);
      }
    }
  }, [newVehiclesList]);

  const handleRedirect = (data) => {
    let redirectPath = `/show-room/${data?.make}/${data?.ownedModelId}`;
    let displayPath;

    if (globalDealer?.dealerId === 550) {
      if (data.isComingSoon && data.title === `C5 GT`) {
        redirectPath = `/showroom-gt/${data?.make}/${data?.ownedModelId}`;
        displayPath = `/models/c5-gt`;
      } else if (data.isComingSoon && data.title === `C9`) {
        redirectPath = `/showroom-gt/${data?.make}/${data?.ownedModelId}`;
        displayPath = `/models/c9`;
      } else if (data.model === 'C9') {
        redirectPath = `/showroom-c9/${data?.make}/${data?.ownedModelId}`;
        displayPath = `/models/${data.model}`;
      } else {
        redirectPath = `/showroom/${data?.make}/${data?.ownedModelId}`;
        displayPath = `/models/${data.model}`;
      }
    }

    history.push({
      pathname: redirectPath,
      state: { ...data },
    });

    window.history.replaceState(null, '', displayPath);
  };

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 0,
  });

  const handleFilter = (make) => {
    setActiveMake(make);
  };

  return (
    <div className={classes.root}>
      {globalDealer.dealerId === DEALERID && (
        <>
          {activeMake === '' && (
            <div className={classes.innerHolder}>
              {vehicleCleaned?.map((v, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`group_card_${i}`}
                  className={classes.groupCard}
                  onClick={() => {
                    handleFilter(v?.id);
                  }}
                >
                  <img alt="" src={v?.heroImage} width="100%" className={classes.groupCardImg} />
                  <img alt="" src={v?.logo} width="40%" className={classes.groupCardLogo} />
                  {v?.make}
                </div>
              ))}
            </div>
          )}
          {activeMake !== '' && (
            <>
              <div className={classes.btnHolder}>
                <button
                  type="button"
                  className={classes.btn}
                  onClick={() => {
                    setActiveMake('');
                  }}
                >
                  Back
                </button>
              </div>
              <div className={classes.innerHolder}>
                {vehicles
                  ?.flat()
                  ?.filter((v) => v.makeId === activeMake)
                  .map((v, i) => {
                    let priceText;
                    if (v.isComingSoon) {
                      priceText = 'R589 990';
                    } else if (v.variant) {
                      priceText = `From ${formatter.format(v.variant.price)}`;
                    } else {
                      priceText = `From ${formatter.format(v.price)}`;
                    }
                    return (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={`vehicle_card_${i}`}
                        className={classes.card}
                        onClick={() => {
                          handleRedirect(v);
                        }}
                      >
                        <div className={classes.cardTextHolder}>
                          <div className={classes.imageHolder}>
                            <img alt="" src={v?.image} width="100%" />
                          </div>
                          <div className={classes.cardTextTitleHolder}>
                            <p className={classes.cardTextTitleAccentTop}>{v.title}</p>
                            <h5 className={classes.cardTextTitle}>
                              <strong>
                                <span className={classes.cardTextTitleAccent}>{priceText}</span>
                              </strong>
                            </h5>
                            <p className={classes.cardTextTitleAccentMore}>More Details</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </>
      )}
      {globalDealer.dealerId !== DEALERID && (
        <div className={classes.innerHolder}>
          {vehicles.flat().map((v, i) => {
            let priceText;
            if (v.isComingSoon && v.title === `C5 GT`) {
              priceText = 'From R589 990';
            } else if (v.isComingSoon && v.title === `C9`) {
              priceText = ' ';
            } else if (v.variant) {
              priceText = `From ${formatter.format(v.variant.price)}`;
            } else {
              priceText = `From ${formatter.format(v.price)}`;
            }
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`master_vehicle_card_${i}`}
                className={classes.card}
                onClick={() => {
                  handleRedirect(v);
                }}
              >
                <div className={classes.cardTextHolder}>
                  <div className={classes.imageHolder}>
                    <img alt="" src={v?.image} width="100%" />
                  </div>
                  <div className={classes.cardTextTitleHolder}>
                    <p className={classes.cardTextTitleAccentTop}>{v.title}</p>
                    <h5 className={classes.cardTextTitle}>
                      <strong>
                        <span className={classes.cardTextTitleAccent}>{priceText}</span>
                      </strong>
                    </h5>
                    <p className={classes.cardTextTitleAccentMore}>More Details</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ShowRoom;
